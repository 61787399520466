import React from 'react'
import {graphql} from 'gatsby'

import {renderRichText} from "gatsby-source-contentful/rich-text"

import Header from "../components/header.js";

import Seo from "../components/seo.js"

import 'bootstrap/dist/css/bootstrap.min.css';

import {Row, Col} from 'react-bootstrap';

import "./blog.css"

import Layout from "../components/layout"

export const query = graphql `
query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
        image {
            file {
              url
            }
          }
        id
        title
        author
        publishedDate(formatString: "MMMM Do, YYYY")
        body {
            raw
    }
}
}
`
const Blog = (props) => {
    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                const alt = "test"
                const url = props.data.contentfulBlogPost.body.references[0].fixed.src
                return (
                    <img
                    src={url}
                    alt={alt}
                    style={{
                    width: "100%",
                    height: "auto",
                    float: "center"
                }}/>
                )
            }
        }
    }
    return (
        <Layout pageInfo={{
            pageName: "blog-template"
        }}>
        <div>
            <Seo title={props.data.contentfulBlogPost.title}/>
            <Header />
            <Row style={{paddingTop: "70px"}}>

                <Col md={{ span: 8, offset: 2 }}>
                <div className="blog-template">
                    <img src={props.data.contentfulBlogPost.image.file.url} style={{width: "100%", paddingBottom: "2%"}}></img>
                    <h1 style={{marginBottom: "5px"}}>{props.data.contentfulBlogPost.title}</h1>
                    <p>{props.data.contentfulBlogPost.publishedDate}, {props.data.contentfulBlogPost.author}</p>
                    <div >{renderRichText(props.data.contentfulBlogPost.body, options)}</div>
                </div>
                </Col>
            </Row>
        </div>
        <hr style={{color: "black", background: "black", marginBottom: "0px"}} />
        </Layout>
    )
}

export default Blog
